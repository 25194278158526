<template>
	<div>
		<div class="home">
			<div id="hotels" class="home__container">
				<div style="margin-bottom:5vh; margin-top:20vh;">
					<!-- <h1>Quality, <em>dependable,</em> on-demand workers with <em>accountable,</em> on-site support</h1> -->
					<h1 class="pb-3">On-Demand Hotel Staffing</h1>
					<p class="page-intro">Let us help you give your guests the hospitality experience they deserve.</p>
					<div data-aos="fade-in" data-aos-delay="1000">
						<a href="#" v-scroll-to="{el: '#scrollHere', offset: -60, duration: 400}">
							<button class="btn btn__ghost btn__mega mt-5 mr-5">Learn More</button>
						</a>
						<button class="btn btn__primary btn__mega mt-5" @click="rModal()">Get Pricing</button>
					</div>
				</div>
			</div>
			<div class="whiteBlock" id="scrollHere">
				<div class="pageBlock align-center flex-column pb-0 text-center" id="element1">
					<h2 class="pb-5">Flexible Staffing Options For Your Hotel Needs</h2>
					<p>We understand how much planning and effort it takes for your hotel to function each day. Our hotel staffing platform is designed to integrate with your workflow to keep you fully-staffed at all times. Take advantage of our local workforce to cover for last-minute call-offs, seasonal (or busy weekend) reinforcements, or give a boost to your everyday team.</p>
				</div>
			</div>
			<div class="greyBlock">
				<div class="pageBlock" id="element">
					<div class="pageBlock__col-1-3 pa-5 flex-column align-center text-center">
						<span><i class="fa-duotone fa-phone-volume fa-4x primaryDark pt-3 pb-5"></i></span>
						<h4 class="mb-3">Call-Offs</h4>
						<p>Call-offs happen, usually when you rooms are full and you can't afford to be short workers. Our local teams are ready to jump in and help, even on short notice.</p>
					</div>
					<div class="pageBlock__col-1-3 pa-5 flex-column align-center text-center">
						<span><i class="fa-duotone fa-tree-large fa-4x primaryDark pt-3 pb-5"></i></span>
						<h4 class="mb-3">Seasonal Reinforcements</h4>
						<p>For some hotels, it's the Holidays. For others, it's football season, or when local conventions are in town. When you have busy weeks or months coming up, we can make sure your roster is full.</p>
					</div>
					<div class="pageBlock__col-1-3 pa-5 flex-column align-center text-center">
						<span><i class="fa-duotone fa-people-group fa-4x primaryDark pt-3 pb-5"></i></span>
						<h4 class="mb-3">Regular Daily Workforce</h4>

						<p>Cooks, Dishwashers, and Housekeepers are in high-demand and most hotels could use at least a few more of them every single day. We're ready to jump in.</p>
					</div>
				</div>
			</div>
			<div class="whiteBlock">
				<div class="pageBlock pt-5 pb-5 mb-5" id="element2">
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/AdobeStock_249236794-min.jpeg?alt=media&token=ce5fa650-160f-4736-8bda-f23f6ae926d6" alt="">
					</div>
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">On-Demand Housekeepers</h2>
							<h4 class="mb-3">Keeping Your Hotel Clean and Ready for Business</h4>
							<p>Housekeepers do more than change sheets, vacuum, and mop. They make sure your rooms are ready when the next guest checks in. They ensure your guests smile when they walk in their rooms. </p>
							<p>Being short housekeepers is something that no hotel can afford, making them one of the most desirable on-demand postions across the country. Share with us your needs and we can have a great local team that's ready to jump in when you need them.</p>
						</div>
					</div>
				</div>
				<div class="pageBlock pt-5 pb-5 mb-5 reverse" id="element3">
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">Temporary Culinary Staff</h2>
							<h4 class="mb-3">Cooks, Dishwashers, and More</h4>
							<p>Kitchen staff was also hit hard by the pandemic and many hotels are still struggling to find and retain culinary workers.</p>
							<p>Room service takes longer. Food trays sit in hallways. Morale and quality suffer as your staff has to do more with less. This is where extra professionals can help - on busy days or every day.</p>
						</div>
					</div>
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/AdobeStock_457451468-min.jpeg?alt=media&token=7b751e94-0faa-4d75-a145-f733b8ee261d" alt="">
					</div>
				</div>
				<div class="pageBlock pt-5 pb-5 mb-5" id="element2">
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/wfewf.jpeg?alt=media&token=68dece69-ddb7-4d51-b04b-d87748386b13" alt="">
					</div>
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">Bar and Restaurant Staff</h2>
							<h4 class="mb-3">Front of House Staffing Needs</h4>
							<p>Just about every busy restaurant in every town is hiring and competition for quality workers is fierce. Most hotels have a good core team, but many struggle with staffing shortages when things are busy.</p>
							<p>While many hospitality workers are hestiant to commit to full-time jobs, they love the flexibily that gig work provides. We can help with servers, hosts, food runners, and more.</p>
						</div>
					</div>
				</div>
				<div class="pageBlock pt-5 pb-5 mb-5 reverse" id="element3">
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">Flexible Catering Staff</h2>
							<h4 class="mb-3">Be Ready for Your Large Events</h4>
							<p>Whether it's twice a week or once a month, schedules for special event staff and catering staff can be less than dependable. Hotels are utilitizing temp staff more and more to fill these roles.</p>
							<p>Not only can we help ensure your banquets, conferences and weddings go off without a hitch, we can make sure that every worker we provide has received special training on your hotel policies and culture.</p>
						</div>
					</div>
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/AdobeStock_149779674-min.jpeg?alt=media&token=c107d748-3b5e-4f72-87ff-9a87c77187b6" alt="">
					</div>
				</div>
			</div>

				<div class="whyHire" id="checklist">
					<h2 class="montserrat">Why Partner With <span>J<em>u</em>mp</span> At Your Hotel?</h2>
					<div class="checklist__wrapper">
						<div class="checklist--item">
							<div class="i-border">
								<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="300"></i>
							</div>
							<p class="new"><span class="strong">60,000+ Workers</span> - We have over 60,000 workers nationwide, with the capacity to handle just about any request in any city.</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="600"></i>
								</div>
								<p class="new"><span class="strong">Customized Training Modules</span> - Does your hotel have specific training or brand requirements? We can add that training to our onboarding process.</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="900"></i>
								</div>
								<p class="new"><span class="strong">Dedicated Account Support</span> - Every hotel has a dedicated team that will work with you daily to make sure that every shift is full and correct.</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="1200"></i>
								</div>
								<p class="new"><span class="strong">Shift Management</span> - We create your shifts, manage applications, and handle last-minute changes. We don't expect you to create and staff your own shifts!</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="1500"></i>
								</div>
								<p class="new"><span class="strong">Client Dashboard</span> - Login for easy access to your orders, your staff, and to plan for future shifts.</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="1800"></i>
								</div>
								<p class="new"><span class="strong">Temp-To-Hire</span> - Sometimes you'll find someone who belongs on your team. Enjoy an easy path to directly hire our workers.</p>
							</div>
						</div>
					</div>
				</div>

				<div class="backLightPrimary">
					<div class="pageBlock align-center flex-column pt-5 pb-5 text-center" >
						<h2 style="color:white;" class="pb-5">Get Pricing and More Information About Our Local Workforce</h2>
						<button class="btn btn__primaryDark btn__large" @click="rModal()">Get Started <i class="fa-duotone fa-arrow-right ml-3"></i></button>

						<transition name="modal">
							<QuoteRequest v-if="showQuote" @close="closeModal()" />
						</transition>
					</div>
				</div>


		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'
import Jumpers from '@/components/Jumpers.vue'
import WorkerBenefits from '@/components/WorkerBenefits.vue'
import QuoteRequest from '@/components/QuoteRequest.vue'
import VenueTypes from '@/components/VenueTypes.vue'

export default {
	name: 'hotels',
	data() {
    return {
    	showQuote: false,
    	showPricing: false,
    	showInsurance: false,
    	showScreen: false,
    	showManage: false,
    	showDifferent: false,
    }
  },
	components: {
		Jumpers,
		WorkerBenefits,
		VenueTypes,
		QuoteRequest
	},
	methods: {
		rModal() {
      this.showQuote = true
    },
    closeModal() {
      this.showQuote = false
    },
	},
	metaInfo: () => ({
    title: 'Jump',
    titleTemplate: '%s | Hotel Staffing Platform',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'Flexible Staffing Options For Your Hotel Needs',
    }],
  })
}
</script>